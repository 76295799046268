<template>
  <form @submit.prevent="createQualification">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Создание</h2>
      <div>
        <UIButton text="Сохранить" leftIcon="bi-save" type="submit" />
      </div>
    </div>

    <div class="row gap-3">
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput v-model="data.name" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableInput v-model="data.description" editable required />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код:</dt>
            <dl class="col">
              <EditableInput v-model="data.code" editable required />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import { API_qualification_create } from "@/services/references/qualification";
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";

export default {
  name: "qualificationRequirementCreate",
  components: { UIButton, EditableInput },
  data() {
    return {
      data: {
        name: "",
        description: "",
        code: "",
      },
    };
  },
  methods: {
    createQualification() {
      API_qualification_create(this.data).then((res) => {
        this.$router.push({
          path: `/references/qualification-requirement/${res.id}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
